import { useEffect, useState } from 'react'
import {
  useDynamicTokens,
  useTokenActivity,
} from '@reservoir0x/reservoir-kit-ui'
import { Box, Flex, FormatCryptoCurrency, Text } from 'components/primitives'
import { Sale } from 'types/Sales'
import { useMarketplaceChain } from 'hooks'
import Link from 'next/link'
import axios from 'axios'

type RecentSalesCardProps = {
  sale: Sale
}

export const RecentSalesCard: React.FC<RecentSalesCardProps> = ({ sale }) => {
  const [bannerImage, setBannerImage] = useState<string>('/SteelVault.png')
  const marketplaceChain = useMarketplaceChain()

  if (!sale) return null

  let tokenQuery: Parameters<typeof useDynamicTokens>['0'] = {
    tokens: `${sale?.token?.contract}:${sale?.token?.tokenId}`,
    includeLastSale: true,
  }

  const { data: tokens } = useDynamicTokens(tokenQuery)
  const token = tokens[0]?.token

  const tokenPrice = tokens[0]?.market?.floorAsk?.price?.amount?.decimal
    ? tokens[0]?.market?.floorAsk?.price?.amount?.decimal
    : '-'
  const collection = token?.collection

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const response = await axios.get(token?.image as string)
        if (response.data) {
          setBannerImage(token?.image as string)
        } else {
          console.error('Failed to fetch data from API')
        }
      } catch (error) {
        console.error('Error fetching data from API:', error)
      }
    }
    fetchDataFromApi()
  }, [token?.image])

  const {
    data: salesActivities,
    isLoading,
    isValidating,
  } = useTokenActivity(`${token?.contract}:${token?.tokenId}`, {
    types: 'sale',
    // sortBy: 'createdAt',
    limit: 1,
  })
  const lastSale = salesActivities[0] || undefined

  const getCurrencySymbol = (currencyAddress: string) => {
    // This is a simplified check. You might need to adjust based on your specific currency addresses
    if (
      currencyAddress.toLowerCase() ===
      '0x0000000000000000000000000000000000000000'
    ) {
      return 'ETH'
    } else if (
      currencyAddress.toLowerCase() ===
      '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
    ) {
      return 'USDC'
    } else {
      return 'Unknown'
    }
  }

  return (
    <Link
      href={`/${marketplaceChain.routePrefix}/asset/${collection?.id}:${token?.tokenId}`}
    >
      <Flex
        direction="column"
        className="actions-card"
        css={{
          flex: '0 0 auto', // This prevents cards from shrinking
          cursor: 'pointer',
          background: '$grey90',
          $$shadowColor: '$colors$panelShadow',
          boxShadow: '0px 0px 12px 0px $$shadowColor',
          p: 0,
          border: '4px solid $greycool',
          scrollSnapAlign: 'start',
          marginRight: '10px',
        }}
      >
        <Flex
          css={{
            mb: '0px',
            width: '100%',
            height: '100%',
            position: 'relative',
            justifyContent: 'center',
          }}
        >
          <Flex
            css={{
              border: '6px solid $mithrilSteel',
              width: '100%',
            }}
          >
            <img
              src={bannerImage as string}
              alt={collection?.name as string}
              style={{
                width: '100%',
                height: '284px',
                objectFit: 'cover',
              }}
            />
          </Flex>
        </Flex>
        <Flex
          direction="column"
          css={{
            width: '100%',
            // height: '100%',
          }}
        >
          <Box
            css={{
              maxWidth: 355,
              lineHeight: 1.5,
              fontSize: 16,
              flex: 1,
              fontWeight: 400,
              display: '-webkit-box',
              color: '$gray80',
              fontFamily: '$body',
              WebkitLineClamp: 3,
              padding: '16px 12px',
              WebkitBoxOrient: 'vertical',
              gap: 6,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '& a': {
                fontWeight: 500,
                cursor: 'pointer',
                textDecoration: 'underline',
              },
            }}
          >
            <Flex
              align="center"
              css={{
                width: 'fit-content',
              }}
            >
              <Text style="h6" as="h6" ellipsify>
                {token?.name ? truncate(token?.name, 22) : '-'}
              </Text>
            </Flex>

            <Text style="subtitle2" color="subtle" as="p">
              {collection?.name ? truncate(collection?.name, 25) : '-'}
            </Text>
            <Text style="subtitle2" color="subtle" as="p">
              {lastSale ? (
                <Flex css={{ gap: '$2', marginTop: 'auto' }}>
                  <Text>Last Sale :</Text>
                  <FormatCryptoCurrency
                    label="Last Sale"
                    logoHeight={12}
                    amount={lastSale?.price?.amount?.decimal}
                    address={lastSale?.price?.currency?.contract}
                    decimals={lastSale?.price?.currency?.decimals}
                    textStyle="subtitle3"
                    maximumFractionDigits={4}
                    css={{
                      color: '$gray7',
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  />
                  <Text>{lastSale?.price?.currency?.symbol || 'USDC'}</Text>
                </Flex>
              ) : null}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Link>
  )
}

const truncate = (str: string, maxLength: number): string => {
  if (str.length <= maxLength) {
    return str
  }

  return `${str.slice(0, maxLength - 3)}...`
}
