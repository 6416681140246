import { Box, Flex, Text, Anchor } from '../../primitives'
import { useState } from 'react'

type CardProps = {
  CardImage?: string
  CardText?: string
  CardLinkTo?: string
  CardLink?: string
}

const HeroCardDescription = ({
  CardImage,
  CardText,
  CardLinkTo,
  CardLink,
}: CardProps) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <Box
      css={{
        position: 'relative',
        cursor: 'pointer',
        width: '100%', // Change from maxWidth to width
        paddingBottom: '100%', // This creates a 1:1 aspect ratio
        margin: '0 5px',
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Box
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Box
          as="img"
          loading="eager"
          alt=""
          src={CardImage}
          css={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <Box
          css={{
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            background:
              'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7))',
            zIndex: 1,
          }}
        />
      </Box>

      <Flex
        direction="column"
        css={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          padding: '15px',
          background: 'linear-gradient(to top, rgba(0,0,0,0.7), transparent)',
          zIndex: 2,
        }}
      >
        <Text
          css={{
            fontWeight: 800,
            marginBottom: '5px',
            background: 'linear-gradient(180deg, #FFFFFF 0%, #B0B0B0 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            textShadow: '0px 0px 10px rgba(255, 255, 255, 0.2)',
          }}
        >
          {CardText}
        </Text>
        {isHover ? (
          <Anchor
            href={CardLink}
            target="_blank"
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              fontSize: '14px',
              fontFamily: 'Inter',
              opacity: 1,
              transform: 'translateY(0)',
              transition: 'opacity 0.5s ease, transform 0.5s ease',
              background: 'linear-gradient(180deg, #FFFFFF 0%, #B0B0B0 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              backgroundClip: 'text',
              textFillColor: 'transparent',
            }}
          >
            {CardLinkTo}
            <Box
              as="img"
              loading="eager"
              alt=""
              src="/arrow.png"
              css={{
                height: '14px',
                width: '14px',
                transform: 'rotate(180deg)',
                filter: 'brightness(0) invert(1)', // This will make the arrow white
              }}
            />
          </Anchor>
        ) : (
          <Anchor
            css={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '8px',
              fontSize: '14px',
              fontFamily: 'Inter',
              opacity: 0,
              transform: 'translateY(10px)',
              transition: 'opacity 0.5s ease, transform 0.5s ease',
              color: 'white !important', // Add this line
            }}
          />
        )}
      </Flex>
    </Box>
  )
}

export default HeroCardDescription
