import { Box } from 'components/primitives'
import { useState, useEffect } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import HeroCardDescription from './HeroCardDescription'

const NewHero = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isMediumScreen, setIsMediumScreen] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
      setIsMediumScreen(window.innerWidth >= 768 && window.innerWidth < 1024)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const cardData = [
    {
      CardImage: '/bitcoinLogo.png',
      CardText: '0% Marketplace Fees',
      CardLinkTo: 'Read More',
      CardLink: 'https://emblem-vault.medium.com/emblem-markets-8ad1957792bf',
    },
    {
      CardImage: '/Satoshi.png',
      CardText: 'Home of Rare Pepe',
      CardLinkTo: 'View Collection',
      CardLink:
        '/ethereum/collection/0x7e6027a6a84fc1f6db6782c523efe62c923e46ff',
    },
    {
      CardImage: 'github.png',
      CardText: 'Build with the Emblem SDK',
      CardLinkTo: 'Build',
      CardLink: 'https://github.com/EmblemCompany/emblem-vault-sdk',
    },
    {
      CardImage:
        'https://img.reservoir.tools/images/v2/mainnet/z9JRSpLYGu7%2BCZoKWtAuAI37ZMpGmBWtUpAQDl1tI6BrcBXKsZNhjp9zTMM8Cvh1qeoSqFyB2nP6JZxhTbBy4fVECWsOZfOhig9GdGHhlPy5Af%2FTiJggogymY%2FXIpA1yNwksa87tIRXonit%2F26vgnA%3D%3D',
      CardText: 'Apply for the Vaultpad (Crosschain Launchpad)',
      CardLinkTo: 'Apply',
      CardLink: 'https://discord.com/invite/BgDcN2jpCJ',
    },
  ]

  const getSlideCount = () => {
    if (isMobile) return 1
    if (isMediumScreen) return 3
    return 4
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: getSlideCount(),
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: isMobile ? '40px' : '60px',
  }

  return (
    <Box
      css={{
        width: '100%',
        background: 'linear-gradient(0deg, #7A7A7D 1%, #000000 100%)',
        overflow: 'hidden',
        padding: '40px 0',
      }}
    >
      <Box css={{ maxWidth: '1600px', margin: '0 auto' }}>
        <Slider {...settings}>
          {cardData.map((card, index) => (
            <Box
              key={index}
              css={{
                padding: '0 10px',
                display: 'flex !important',
                justifyContent: 'center',
                alignItems: 'center',
                '@media (max-width: 767px)': {
                  width: '100%', // Full width on mobile
                  '& > div': {
                    width: 'calc(100% - 40px)', // Adjust card width to show a hint of the next card
                    margin: '0 auto',
                  },
                },
              }}
            >
              <HeroCardDescription {...card} />
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  )
}

export default NewHero
